import React from 'react';
import './css/index.css';

import Rain from './components/Rain';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <p>Pour Tomáš</p>
        </div>
        <div>
          <Rain/>
        </div>
      </header>
    </div>
  );
}

export default App;
